<template>
  <div>
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-header">
          <h4 class="card-title">
            <span>{{RelName}}</span>
          </h4>
     

          <b-col v-if="showFiltro ? false : true ">
            <div style="float:right">
              <div class="DivBotaoLadoLado" >
              <b-button variant="danger" @click="$router.push('../Relatorios')">
                   <span>RETORNAR</span>
               </b-button>
              </div>
               
               <div class="DivBotaoLadoLado" >
               <b-button v-b-toggle="'collapse-filtro'" variant="primary" >
                 <span>FILTROS</span>
               </b-button>
               </div>
            </div>
          </b-col>

        </div>

        <b-collapse visible  id="collapse-filtro">
        <div class="card-body">
          
          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
            label="Dt Negociação Inicial:"
            >
            <b-form-datepicker
                 id="PeridoIni"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="PeridoIni"
                 placeholder="Selecione"
                 :required="true"
               />
                  </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
            label="Dt Negociação Final:"
            >
            <b-form-datepicker
                 id="PeridoFin"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="PeridoFin"
                 placeholder="Selecione"
                 :required="true"
               />
                  </b-form-group>
            </b-col>

            

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Vendedor:"  >
                <multiselect
                    v-model="CodVend"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodVendOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>
          
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Região:"  >

                  <multiselect
                    v-model="CodReg"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodRegOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

            

            <b-col md="6" xl="4" class="mb-1">
              <b-form-group label="Tipo de Operação:"  >

                  <multiselect
                    v-model="TipOper"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="TipOperOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>


             <b-col
                md="6"
                xl="2"
                class="mb-1"
              >
                <b-form-group
                  label="Nº Unico:"
                >
                  <b-form-input
                    id="NUNOTA"
                    placeholder=""
                    v-model="NUNOTA"
                    type="number"
                  />
                 </b-form-group>
            </b-col>


            <b-col
                md="6"
                xl="2"
                class="mb-1"
              >
                <b-form-group
                  label="Nº Nota:"
                >
                  <b-form-input
                    id="NUMNOTA"
                    placeholder=""
                    v-model="NUMNOTA"
                  />
                 </b-form-group>
            </b-col>

            <b-col
                md="6"
                xl="2"
                class="mb-1"
              >
                <b-form-group
                  label="Nº Pedido Cliente:"
                >
                  <b-form-input
                    id="NUMPED"
                    placeholder=""
                    v-model="NUMPED"
                  />
                 </b-form-group>
            </b-col>

          </b-row>

          <b-row>

             <b-col md="6" xl="8" class="mb-1">
              <b-form-group label="Parceiro:"  >

                  <multiselect
                    v-model="CodParc"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodParcOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>


              </b-form-group>
            </b-col>
            <b-col md="6" xl="2" class="mb-1">
              <b-form-group label="Parceiros Ativos:"  >
                <multiselect
                    v-model="Ativo"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="AtivoOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

          </b-row>


        <b-row>
            
            <b-col
                md="6"
                xl="12"
                class="mb-1"
              >
              <div class="DivBotaoLadoLado" >
               <b-button
              variant="danger"
              type="button"
              @click="$router.push('../Relatorios')"
            >
              <span>RETORNAR</span>
            </b-button>
              </div>
              <div class="DivBotaoLadoLado" >
            <b-button v-b-toggle="'collapse-filtro'"
              variant="primary"
              @click="ConsultarRelatorio"
            >
              <span>CONSULTAR</span>
            </b-button>
              </div>
          </b-col>
         </b-row>
         
        </div>
        </b-collapse>
        
      </div>
    </div>

    <form action="https://www.webdanfe.com.br/danfe/GeraDanfe.php" target="_blank" name="one" enctype="multipart/form-data" method="post">

    <div class="col-12" v-if="TabRelAcompanhamentoPedPosition >= 0">
            <div class="card mb-4">
              <div class="card-body pb-60" >
                
                <div style="float:right" > 
                   <b-button
                       variant="danger"
                       @click="TabRelAcompanhamentoPedRowBack(-1)"
                       v-if="TabRelAcompanhamentoPedPosition > 0"
                    >
                        <span>VOLTAR</span>
                   </b-button>
                </div>
              
              <div v-if="TabRelAcompanhamentoPedPosition == 0">
                <h4 class="fw-bolder mb-1">Acompanhamento de Pedidos</h4>  
                
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabRelAcompanhamentoPedNV1"
                  :fields="TabRelAcompanhamentoPedNV1fields"
                  selectable
                  @row-selected="TabRelAcompanhamentoPedNV1onRowSelected"
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >

                <template #cell(VLRNOTA)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(show_details)="row">
                   <b-button size="sm" variant="warning" @click="row.toggleDetails" class="mr-2">
                     {{ row.detailsShowing ? '-' : '+'}}
                   </b-button>
           
                 </template>

                 <template #row-details="row">
                  <b-card>

                   <b-row>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Nº Único:</b><br>
                        {{ row.item.NUNOTA }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Nº Nota:</b><br>
                        {{ row.item.NUMNOTA }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Nº Pedido Cliente:</b><br>
                        {{ row.item.NUMPEDIDO }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Nº Único Ref.:</b><br>
                        {{ row.item.NUNOTAREF }}
                    </b-col>
                    
                    <b-col md="6" xl="2" class="mb-1">
                        <b>Status Nota:</b><br>
                        {{ row.item.STATUSNOTA }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Confirmada:</b><br>
                        {{ row.item.CONFIRMADA }}
                    </b-col>

                  </b-row>
                  <b-row>
                    <b-col md="6" xl="2" class="mb-1">
                        <b>Vlr. Nota:</b><br>
                        {{ 'R$ ' + TextFormat(row.item.VLRNOTA,'money') }}
                    </b-col>
                    <b-col md="6" xl="2" class="mb-1">
                        <b>Vlr. Nota sem ST:</b><br>
                        {{ 'R$ ' + TextFormat(row.item.VLRNOTA_SEMST,'money') }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Dt. Prev. Entrega:</b><br>
                        {{ row.item.DTPREVENT }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Dt. Faturamento:</b><br>
                        {{ row.item.DTFATUR }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Peso:</b><br>
                        {{ row.item.PESO }}
                    </b-col>


                    <b-col md="6" xl="2" class="mb-1">
                        <b>Peso Bruto:</b><br>
                        {{ row.item.PESOBRUTO }}
                    </b-col>

                  </b-row>

                  <b-row>
                    <b-col md="6" xl="2" class="mb-1">
                        <b>Vlr. ICMS:</b><br>
                        {{ 'R$ ' + TextFormat(row.item.VLRICMS,'money') }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Vlr. Substituição:</b><br>
                        {{ 'R$ ' + TextFormat(row.item.VLRSUBST,'money') }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Vlr. Rapel:</b><br>
                        {{ 'R$ ' + TextFormat(row.item.VLRRAPEL,'money') }}
                    </b-col>

                  </b-row>

  
                  <hr>
                  <b-row>
                      <b-col md="6" xl="2" class="mb-1">
                        <b>Cód Parceiro:</b><br>
                        {{ row.item.CODPARC }}
                      </b-col>
                      <b-col md="6" xl="4" class="mb-1">
                        <b>Parceiro:</b><br>
                        {{ row.item.NOMEPARC }}
                      </b-col>
                      <b-col md="6" xl="6" class="mb-1">
                        <b>Razão Social:</b><br>
                        {{ row.item.RAZAOSOCIAL }}
                      </b-col>
                  </b-row>

                 <hr>
                  <b-row>
                      <b-col md="6" xl="2" class="mb-1">
                        <b>Cód Vendedor:</b><br>
                        {{ row.item.CODVEND }}
                      </b-col>

                      <b-col md="6" xl="2" class="mb-1">
                        <b>Vendedor:</b><br>
                        {{ row.item.APELIDO }}
                      </b-col>

                  </b-row>

                  <hr>
                  <b-row>
                      <b-col md="6" xl="2" class="mb-1">
                        <b>Cód Região:</b><br>
                        {{ row.item.CODREG }}
                      </b-col>

                      <b-col md="6" xl="2" class="mb-1">
                        <b>Região:</b><br>
                        {{ row.item.NOMEREG }}
                      </b-col>

                  </b-row>

                  <hr>
                  <b-row>
                      <b-col md="6" xl="2" class="mb-1">
                        <b>Cód TOP:</b><br>
                        {{ row.item.CODTIPOPER }}
                      </b-col>
                      <b-col md="6" xl="4" class="mb-1">
                        <b>TOP:</b><br>
                        {{ row.item.DESCROPER }}
                      </b-col>
                  </b-row>

                  <div v-if="row.item.MOTIVODEVOLUCAO != ''">
                  <hr>
                  <b-row>
                      <b-col md="6" xl="12" class="mb-1">
                        <b>Motivo Devolução:</b><br>
                        {{ row.item.MOTIVODEVOLUCAO }}
                      </b-col>
                  </b-row>
                  </div>

                  <hr>
                  <b-row>
                      <b-col md="6" xl="12" class="mb-1">
                        <b>Observação:</b><br>
                        {{ row.item.OBSERVACAO }}
                      </b-col>
                  </b-row>
        
                  
                  <hr>
                   <div class="DivBotaoLadoLado" >
                    <b-button size="sm" variant="secondary" @click="row.toggleDetails">Esconder Detalhe</b-button>
                   </div>
                    <div class="DivBotaoLadoLado" >
                      <b-button size="sm" variant="warning" @click="TabRelAcompanhamentoPedNV1onRowSelected([row.item])">Mais Detalhes</b-button>
                    </div>
                      <div class="DivBotaoLadoLado" v-if="TipOper.code == 'V'" > 
                        <b-button size="sm" variant="success" type="submit" value="enviar">
                          <feather-icon
                          size="15"
                          icon="DownloadIcon"
                          />
                          PDF
                        </b-button>
                        <textarea name="arquivoXml" cols="150" rows="50" style="display: none;" >
                          {{row.item.XMLENVCLI}}
                        </textarea>
                      </div>

                  
                </b-card>
               </template>

               <template v-slot:custom-foot>
                  <tr>
                    <td class="bg-secondary text-white" colspan="8">
                     Total
                    </td>

                    <td class="bg-secondary text-white" colspan="6" > 
                      {{ 'R$ ' + TextFormat(TabRelAcompanhamentoPedNV1Total('VLRNOTA'),'money')}}
                    </td>
                    
                  </tr>
               </template>

                </b-table>
                
                <div class="col-2" style="float:right">
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelAcompanhamentoPedNV1','RelatorioAcompanhamentoPedidos')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>

                
              </div>

              <div v-if="TabRelAcompanhamentoPedPosition == 1">
                <h4 class="fw-bolder mb-1">
                    Detalhe pedido:                    
                    <a @click="TabRelAcompanhamentoPedRowBack(0)">
                       <u><b>Nº Unico: {{TabRelAcompanhamentoPedSelect[0].NUNOTA}}</b></u>
                   </a>
                   
                </h4>


              <b-card>

                   <b-row>


                    <b-col md="6" xl="2" class="mb-1">
                        <b>Nº Nota:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].NUMNOTA }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Nº Pedido Cliente:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].NUMPEDIDO }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Nº Único Ref.:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].NUNOTAREF }}
                    </b-col>
                    
                    <b-col md="6" xl="2" class="mb-1">
                        <b>Status Nota:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].STATUSNOTA }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Confirmada:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].CONFIRMADA }}
                    </b-col>

                  </b-row>
                  <b-row>
                    <b-col md="6" xl="2" class="mb-1">
                        <b>Vlr. Nota:</b><br>
                        {{ 'R$ ' + TextFormat(TabRelAcompanhamentoPedSelect[0].VLRNOTA,'money') }}
                    </b-col>
                    <b-col md="6" xl="2" class="mb-1">
                        <b>Vlr. Nota sem ST:</b><br>
                        {{ 'R$ ' + TextFormat(TabRelAcompanhamentoPedSelect[0].VLRNOTA_SEMST,'money') }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Dt. Prev. Entrega:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].DTPREVENT }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Dt. Faturamento:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].DTFATUR }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Peso:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].PESO }}
                    </b-col>


                    <b-col md="6" xl="2" class="mb-1">
                        <b>Peso Bruto:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].PESOBRUTO }}
                    </b-col>

                  </b-row>

                  <b-row>
                    <b-col md="6" xl="2" class="mb-1">
                        <b>Vlr. ICMS:</b><br>
                        {{ 'R$ ' + TextFormat(TabRelAcompanhamentoPedSelect[0].VLRICMS,'money') }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Vlr. Substituição:</b><br>
                        {{ 'R$ ' + TextFormat(TabRelAcompanhamentoPedSelect[0].VLRSUBST,'money') }}
                    </b-col>

                    <b-col md="6" xl="2" class="mb-1">
                        <b>Vlr. Rapel:</b><br>
                        {{ 'R$ ' + TextFormat(TabRelAcompanhamentoPedSelect[0].VLRRAPEL,'money') }}
                    </b-col>

                  </b-row>

  
                  <hr>
                  <b-row>
                      <b-col md="6" xl="2" class="mb-1">
                        <b>Cód Parceiro:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].CODPARC }}
                      </b-col>
                      <b-col md="6" xl="4" class="mb-1">
                        <b>Parceiro:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].NOMEPARC }}
                      </b-col>
                      <b-col md="6" xl="6" class="mb-1">
                        <b>Razão Social:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].RAZAOSOCIAL }}
                      </b-col>
                  </b-row>

                 <hr>
                  <b-row>
                      <b-col md="6" xl="2" class="mb-1">
                        <b>Cód Vendedor:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].CODVEND }}
                      </b-col>

                      <b-col md="6" xl="2" class="mb-1">
                        <b>Vendedor:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].APELIDO }}
                      </b-col>

                  </b-row>

                  <hr>
                  <b-row>
                      <b-col md="6" xl="2" class="mb-1">
                        <b>Cód Região:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].CODREG }}
                      </b-col>

                      <b-col md="6" xl="2" class="mb-1">
                        <b>Região:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].NOMEREG }}
                      </b-col>

                  </b-row>

                  <hr>
                  <b-row>
                      <b-col md="6" xl="2" class="mb-1">
                        <b>Cód TOP:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].CODTIPOPER }}
                      </b-col>
                      <b-col md="6" xl="4" class="mb-1">
                        <b>TOP:</b><br>
                        {{ TabRelAcompanhamentoPedSelect[0].DESCROPER }}
                      </b-col>
                  </b-row>
                  
                </b-card>

                  <hr>
                  <h4 class="fw-bolder mb-1">
                   Lista de Produtos:
                   </h4>
                 <b-table
                  striped
                  hover
                  responsive
                  sticky-header
                  small
                  :items="TabRelAcompanhamentoPedNV2"
                  :fields="TabRelAcompanhamentoPedNV2fields"
                  selectable
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                
                >
     

                <template #cell(QTDNEG)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(VLRUNIT)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(VLRSUBST)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(VLRDESC)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(VLRTOT)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(PESO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(DESC_PRC)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>
                
                <template v-slot:custom-foot>
                  <tr>
                    <td class="bg-secondary text-white" colspan="6">
                     Total
                    </td>

                    <td class="bg-secondary text-white" colspan="2" > 
                      {{ TextFormat(TabRelAcompanhamentoPedNV2Total('QTDNEG'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"  > 
                      {{ 'R$ ' + TextFormat(TabRelAcompanhamentoPedNV2Total('VLRSUBST'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"  colspan="2" > 
                      {{ 'R$ ' + TextFormat(TabRelAcompanhamentoPedNV2Total('VLRDESC'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"  > 
                      {{ 'R$ ' + TextFormat(TabRelAcompanhamentoPedNV2Total('VLRTOT'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"  > 
                      {{ TextFormat(TabRelAcompanhamentoPedNV2Total('PESO'),'money')}}
                    </td>

                  </tr>
                </template>

                </b-table>
                
                <div class="col-2" style="float:right"> 
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelAcompanhamentoPedNV2','RelatorioAcompanhamentoPedidosDetalhe')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>
              </div>


             </div>
            </div>
      </div>
    </form>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import 'bootstrap-icons/font/bootstrap-icons.css'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'

import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BTable,
  BFormSelect,
  BFormInput,
  BCollapse,
  BCard,
  VBToggle,
  BFormDatepicker,
} from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts,
    Multiselect,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
    BFormDatepicker,
  },
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      showFiltro: true,
      FiltroRelatorio: [],
      RelName: '',
      RelRota: '',
      TabRelAcompanhamentoPedNV1: [
      ],
      TabRelAcompanhamentoPedNV1fields: [
        { key: 'show_details', label: 'Detalhe', sortable: false },
        { key: 'CODPARC', label: 'Cód. Parceiro', sortable: true, tdClass: 'td50'  },
        { key: 'NOMEPARC', label: 'Parceiro', sortable: true, tdClass: 'td300'  },
        { key: 'CODTIPOPER', label: 'Cód.TOP', sortable: true, tdClass: 'td50'  },
        { key: 'DESCROPER', label: 'TOP', sortable: true, tdClass: 'td250'  },
        { key: 'NUNOTAREF', label: 'Nº Único Ref.', sortable: false, tdClass: 'td100' },
        { key: 'NUNOTA', label: 'Nº Único', sortable: true,tdClass: 'td100' },
        { key: 'NUMNOTA', label: 'Nº Nota', sortable: true,tdClass: 'td100' },
        { key: 'NUMPEDIDO', label: 'Nº Ped. Cliente', sortable: true,tdClass: 'td80' },
        { key: 'VLRNOTA', label: 'Vlr.Nota', sortable: false,tdClass: 'td100' },
        { key: 'STATUSNOTA', label: 'Status Nota', sortable: true,tdClass: 'td100' },
        { key: 'CONFIRMADA', label: 'Confirmada', sortable: true,tdClass: 'td100' },
        { key: 'DTNEG', label: 'Dt.Negociação', sortable: true,tdClass: 'td100' },
        { key: 'DTPREVENT', label: 'Dt.Prev.Entrega', sortable: true,tdClass: 'td100' },
        { key: 'DTFATUR', label: 'Dt.Faturamento', sortable: true,tdClass: 'td100' },
        { key: 'XMLENVCLI', label: 'XML', sortable: true,tdClass: 'td0'}.hide,
      ],
      TabRelAcompanhamentoPedNV2: [
      ],
      TabRelAcompanhamentoPedNV2fields: [
        { key: 'NUNOTA', label: 'Nº Unico', sortable: false }.hide,
        { key: 'CODPROD', label: 'Cód.Produto', sortable: true },
        { key: 'AD_CODESTRAPOLO', label: 'Cód.Estruturado', sortable: true },
        { key: 'REFERENCIA', label: 'Cód.Alternativo', sortable: true },
        { key: 'DESCRPROD', label: 'Produto', sortable: false, tdClass: 'td400' },
        { key: 'COMPLDESC', label: 'Complemento', sortable: false, tdClass: 'td200'},
        { key: 'CODVOL', label: 'Unidade', sortable: false },
        { key: 'QTDNEG', label: 'Qtd.Negociada', sortable: false },
        { key: 'VLRUNIT', label: 'Vlr.Unitario', sortable: false },
        { key: 'VLRSUBST', label: 'Vlr.Substituição', sortable: false },
        { key: 'VLRDESC', label: 'Vlr.Desconto', sortable: false },
        { key: 'DESC_PRC', label: 'Perc.Desconto', sortable: false },
        { key: 'VLRTOT', label: 'Vlr.Total', sortable: false},
        { key: 'PESO', label: 'Peso', sortable: false },
      ],
      PeridoIni: '',
      PeridoFin: '',
      CodVend: [],
      CodVendOption: [],      
      CodReg: [],
      CodRegOption: [],
      Ativo: { "name": "Ambos", "code": "A" },
      AtivoOption: [
        { name: 'Ambos', code: 'A' },
        { name: 'Sim', code: 'S' },
        { name: 'Não', code: 'N' },
      ],
      CodParc: [],
      CodParcOption: [],
      TipOper: [],
      TipOperOption: [],
      NUNOTA: '',
      NUMNOTA: '',
      NUMPED: '',
      exportarParaSelected: 'Exporte',
      exportarParaOptions: [
        { value: 'Exporte', text: 'Exporte' },
        { value: 'XLSX', text: 'Excel' },
      ],
      TabRelAcompanhamentoPedPosition: -1,
      XMLDANFE: '',
    }
  },
  methods: {
    async MostraReport() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Report/MostraReport?CODUSU=${usuario.codusu}&ROTA=${this.RelRota}`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data != null) {
            this.RelName = response.data.descricaoRelatorio
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarOpcoes() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Vendedor`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.CodVendOption = response.data
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Regiao`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.CodRegOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }


      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=TOPGrupoOperacao`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.TipOperOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }


     if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Cliente`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.CodParcOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }


      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarRelatorio() {
      Spinner.StarLoad()

      //Inicio Dados Base Relatorio
      this.showFiltro = false
      const usuario = this.$session.get('usuario')
      let notification = ""
      this.FiltroRelatorio = []
      //Fim Dados Base Relatorio

      //Inicio Limpa Dados Retorno
      this.TabRelAcompanhamentoPedNV1 = []
      //Fim Limpa Dados Retorno


      //Inicio Volta Todos Niveis para posição inicial
      this.TabRelAcompanhamentoPedRowBack(0)
      //Fim Volta Todos Niveis para posição inicial

      //Inicio Pega parametros de Parametros
       if(this.PeridoIni != "")
       {
         const Filtro = {
           parametro: 'PERIODO_INI',
           valor: this.PeridoIni,
         }
         this.FiltroRelatorio.push(Filtro)
       }

       if(this.PeridoFin != "")
       {
         const Filtro = {
           parametro: 'PERIODO_FIN',
           valor: this.PeridoFin,
         }
         this.FiltroRelatorio.push(Filtro)
       }
      
      this.CodVend.forEach(Item => {
        const Filtro = {
          parametro: 'CODVEND',
          valor: Item.code,
        }
        this.FiltroRelatorio.push(Filtro)
      })
      
      this.CodReg.forEach(Item => {
        const Filtro = {
          parametro: 'CODREG',
          valor: Item.code,
        }
        this.FiltroRelatorio.push(Filtro)
      })
    
      
      if(this.TipOper != null){
         if(Array.isArray(this.TipOper) == false){
             const Filtro = {
             parametro: 'TIPOPER',
             valor: this.TipOper.code,
           }
           this.FiltroRelatorio.push(Filtro)
         }
      }
      
      this.CodParc.forEach(Item => {
        const Filtro = {
          parametro: 'CODPARC',
          valor: Item.code,
        }
        this.FiltroRelatorio.push(Filtro)
      })
      

      if(this.Ativo != null){
        if(Array.isArray(this.Ativo) == false)
        {
          const Filtro = {
            parametro: 'ATIVO',
            valor: this.Ativo.code,
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }

      if(this.NUNOTA != "")
       {
         const Filtro = {
           parametro: 'NUNOTA',
           valor: this.NUNOTA,
         }
         this.FiltroRelatorio.push(Filtro)
       }

       if(this.NUMNOTA != "")
       {
         const Filtro = {
           parametro: 'NUMNOTA',
           valor: this.NUMNOTA,
         }
         this.FiltroRelatorio.push(Filtro)
       }
       
       if(this.NUMPED != "")
       {
         const Filtro = {
           parametro: 'NUMPED',
           valor: this.NUMPED,
         }
         this.FiltroRelatorio.push(Filtro)
       }

       //Fim Pega parametros de Parametros

      let ParametroJson = ''
      let URLpost = ''
      
      if(notification === '') {
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelAcompanhamentoPedido?CODUSU=${usuario.codusu}&Nivel=NV1`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.TabRelAcompanhamentoPedNV1 = response.data
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })

    }



     if(notification === '') {
       Spinner.EndLoad()
     }

    },
    TabRelAcompanhamentoPedNV1Total(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'VLRNOTA':
          Valor = this.TabRelAcompanhamentoPedNV1.reduce((accum, TabRelAcompanhamentoPedNV1) => { return accum + TabRelAcompanhamentoPedNV1.VLRNOTA }, 0.0)
        break
        
       
      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
    TabRelAcompanhamentoPedNV2Total(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'QTDNEG':
          Valor = this.TabRelAcompanhamentoPedNV2.reduce((accum, TabRelAcompanhamentoPedNV2) => { return accum + TabRelAcompanhamentoPedNV2.QTDNEG }, 0.0)
        break

        case 'VLRUNIT':
          Valor = this.TabRelAcompanhamentoPedNV2.reduce((accum, TabRelAcompanhamentoPedNV2) => { return accum + TabRelAcompanhamentoPedNV2.VLRUNIT }, 0.0)
        break
        
        case 'VLRSUBST':
          Valor = this.TabRelAcompanhamentoPedNV2.reduce((accum, TabRelAcompanhamentoPedNV2) => { return accum + TabRelAcompanhamentoPedNV2.VLRSUBST }, 0.0)
        break

        case 'VLRDESC':
          Valor = this.TabRelAcompanhamentoPedNV2.reduce((accum, TabRelAcompanhamentoPedNV2) => { return accum + TabRelAcompanhamentoPedNV2.VLRDESC }, 0.0)
        break

        case 'VLRTOT':
          Valor = this.TabRelAcompanhamentoPedNV2.reduce((accum, TabRelAcompanhamentoPedNV2) => { return accum + TabRelAcompanhamentoPedNV2.VLRTOT }, 0.0)
        break

        case 'PESO':
          Valor = this.TabRelAcompanhamentoPedNV2.reduce((accum, TabRelAcompanhamentoPedNV2) => { return accum + TabRelAcompanhamentoPedNV2.PESO }, 0.0)
        break
       
      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
    TabRelAcompanhamentoPedRowBack(RowBackTo) {
       if(RowBackTo == -1){
         this.TabRelAcompanhamentoPedPosition = this.TabRelAcompanhamentoPedPosition-1
       }else{
        this.TabRelAcompanhamentoPedPosition = RowBackTo
       }
    },
    TabRelAcompanhamentoPedNV1onRowSelected(item) {
      if(item.length > 0)
      {
        if(item[0].NUNOTA !== "" && item[0].NUNOTA != undefined)
        {
          this.TabRelAcompanhamentoPedPosition = 1
          this.TabRelAcompanhamentoPedSelect = item
          this.ConsultarTabRelAcompanhamentoPedNV2(item[0].NUNOTA)
        }
      }
    },
    async ConsultarTabRelAcompanhamentoPedNV2(NUNOTA) {
      Spinner.StarLoad()

      //Inicio Dados Base Relatorio
      let notification = ''
      const usuario = this.$session.get('usuario')
      //Fim Dados Base Relatorio
      
      //Inicio Limpa Dados Retorno
      this.TabRelAcompanhamentoPedNV2 = []
      // Fim Limpa Dados Retorno

      //Cria Filtro para Segundo Nivel
      const FiltroRelatorioNv = this.FiltroRelatorio.slice()

      //Inicio Pega parametros de Parametros
      let FiltroAdd = {
        parametro: 'NUNOTA',
        valor: NUNOTA.toString(),
      }
      FiltroRelatorioNv.push(FiltroAdd)
      //Fim Pega parametros de Parametros
      
      const ParametroJson = JSON.parse(JSON.stringify(FiltroRelatorioNv))
      const URLpost = `/Report/ExecutaRelAcompanhamentoPedido?CODUSU=${usuario.codusu}&Nivel=NV2`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.TabRelAcompanhamentoPedNV2 = response.data
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }
    },
    TextFormat(valor, tipo) {
      return UtilVUE.TextFormat(valor, tipo)
    },
    ExportarDoc (ElementToExport,DocName) {
      
      let DataExport = ""
      switch (ElementToExport) {
               case 'TabRelAcompanhamentoPedNV1':
                DataExport = this.TabRelAcompanhamentoPedNV1
               break
               case 'TabRelAcompanhamentoPedNV2':
                DataExport = this.TabRelAcompanhamentoPedNV1
              break
      }    
      UtilVUE.ExportDoc(this.exportarParaSelected,DocName,ElementToExport,DataExport)
      this.exportarParaSelected = 'Exporte'
      return

    },
    async ConsultarXML(NUNOTA){
            Spinner.StarLoad()
            const usuario = this.$session.get('usuario')
            let notification = ""

            let ParametroJson = ''
            let URLpost = ''

            URLpost = `/Report/ExecutaRelVendas?CODUSU=${usuario.codusu}&_NUNOTA=${NUNOTA}`
            await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                if (response.status === 200) {
                    if(response.data.length > 0){
                        this.XMLDANFE = response.data[0].XMLDANFE
                    }
                }
            }).catch(error => {
                notification = ConexaoAPI.ValidaErro(error)
                AlertaMsg.FormataMsg(notification)
            })

            if(notification === '') {
                setTimeout(() =>{
                    Spinner.EndLoad();
                },1000);
            }
    },
    // Função para acionar o evento de envio após o clique no botão "ConsultarXML"
   async consultarXMLAndSubmit(nunota) {
      // Chame a função ConsultarXML com o argumento necessário (nunota)
      this.ConsultarXML(nunota);
      if (this.XMLDANFE != ""){
        // Acione o evento de envio após o clique no botão
        document.getElementById("submitButton").click();
      }
    }

  },
  computed: {
  },
  mounted() {
    if (this.$route.name != null) {
      this.RelRota = this.$route.name
      this.PeridoIni = new Date()
      this.PeridoFin = new Date()
      this.MostraReport()
      this.ConsultarOpcoes()
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
